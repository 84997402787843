/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  outline: none !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.swal2-container {
  z-index: 9999999999 !important;
}

/* You can add global styles to this file, and also import other style files */
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}

/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v38/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
}

/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v37/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2)
    format("woff2");
}

/* fallback */
@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconssharp/v38/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvR.woff2)
    format("woff2");
}

/* fallback */
@font-face {
  font-family: "Material Icons Two Tone";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconstwotone/v36/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu0.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-sharp {
  font-family: "Material Icons Sharp";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
  font-family: "Material Icons Two Tone";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

a:hover {
  text-decoration: none;
}

::ng-deep :root {
  /* label */
  --select2-label-text-color: #000;
  --select2-required-color: red;

  /* selection */
  --select2-selection-border-radius: 4px;
  --select2-selection-background: #fff;
  --select2-selection-disabled-background: #eee;
  --select2-selection-border-color: #aaa;
  --select2-selection-focus-border-color: #000;
  --select2-selection-text-color: #444;

  /* selection: choice item (multiple) */
  --select2-selection-choice-background: #e4e4e4;
  --select2-selection-choice-text-color: #000;
  --select2-selection-choice-border-color: #aaa;
  --select2-selection-choice-close-color: #999;
  --select2-selection-choice-hover-close-color: #333;

  /* placeholder */

  --select2-placeholder-color: #999;

  /* arrow */
  --select2-arrow-color: #888;

  /* dropdown panel */
  --select2-dropdown-background: #fff;
  --select2-dropdown-border-color: #aaa;

  /* search field */
  --select2-search-border-color: #aaa;
  --select2-search-background: #fff;
  --select2-search-border-radius: 0px;

  /* dropdown option */
  --select2-option-text-color: #000;
  --select2-option-disabled-text-color: #999;
  --select2-option-disabled-background: transparent;
  --select2-option-selected-text-color: #000;
  --select2-option-selected-background: #ddd;
  --select2-option-highlighted-text-color: #fff;
  --select2-option-highlighted-background: #5897fb;
  --select2-option-group-text-color: gray;
  --select2-option-group-background: transparent;

  /* hint */
  --select2-hint-text-color: #888;

  /* for Material ------------------------------------------*/
  --select2-material-underline: #ddd;
  --select2-material-underline-active: #5a419e;
  --select2-material-underline-disabled: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.26) 0,
    rgba(0, 0, 0, 0.26) 33%,
    transparent 0
  );
  --select2-material-underline-invalid: red;
  --select2-material-placeholder-color: rgba(0, 0, 0, 0.38);
  --select2-material-selection-background: #ddd;
  --select2-material-option-selected-background: rgba(0, 0, 0, 0.04);
  --select2-material-option-highlighted-text-color: #000;
  --select2-material-option-selected-text-color: #ff5722;
}

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 100000;
  left: 0;
  top: 0;

  > span {
    background: #0d3b5d;
    margin: 0 2px;
    animation: bouncingLoader 0.6s infinite alternate;

    &:nth-child(4) {
      background-color: #42b4e7;
    }

    &:nth-child(1) {
      animation-delay: 0.4s;
    }

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.2s;
    }

    &:nth-child(4) {
      animation-delay: 0.1s;
    }
  }
}
/* You can add global styles to this file, and also import other style files */
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v70/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2)
    format("woff2");
}
/* fallback */
@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v38/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
    format("woff2");
}
/* fallback */
@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconsround/v37/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2)
    format("woff2");
}
/* fallback */
@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconssharp/v38/oPWQ_lt5nv4pWNJpghLP75WiFR4kLh3kvmvR.woff2)
    format("woff2");
}
/* fallback */
@font-face {
  font-family: "Material Icons Two Tone";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialiconstwotone/v36/hESh6WRmNCxEqUmNyh3JDeGxjVVyMg4tHGctNCu0.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-sharp {
  font-family: "Material Icons Sharp";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
  font-family: "Material Icons Two Tone";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

a:hover {
  text-decoration: none;
}

::ng-deep :root {
  /* label */
  --select2-label-text-color: #000;
  --select2-required-color: red;

  /* selection */
  --select2-selection-border-radius: 4px;
  --select2-selection-background: #fff;
  --select2-selection-disabled-background: #eee;
  --select2-selection-border-color: #aaa;
  --select2-selection-focus-border-color: #000;
  --select2-selection-text-color: #444;

  /* selection: choice item (multiple) */
  --select2-selection-choice-background: #e4e4e4;
  --select2-selection-choice-text-color: #000;
  --select2-selection-choice-border-color: #aaa;
  --select2-selection-choice-close-color: #999;
  --select2-selection-choice-hover-close-color: #333;

  /* placeholder */

  --select2-placeholder-color: #999;

  /* arrow */
  --select2-arrow-color: #888;

  /* dropdown panel */
  --select2-dropdown-background: #fff;
  --select2-dropdown-border-color: #aaa;

  /* search field */
  --select2-search-border-color: #aaa;
  --select2-search-background: #fff;
  --select2-search-border-radius: 0px;

  /* dropdown option */
  --select2-option-text-color: #000;
  --select2-option-disabled-text-color: #999;
  --select2-option-disabled-background: transparent;
  --select2-option-selected-text-color: #000;
  --select2-option-selected-background: #ddd;
  --select2-option-highlighted-text-color: #fff;
  --select2-option-highlighted-background: #5897fb;
  --select2-option-group-text-color: gray;
  --select2-option-group-background: transparent;

  /* hint */
  --select2-hint-text-color: #888;

  /* for Material ------------------------------------------*/
  --select2-material-underline: #ddd;
  --select2-material-underline-active: #5a419e;
  --select2-material-underline-disabled: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.26) 0,
    rgba(0, 0, 0, 0.26) 33%,
    transparent 0
  );
  --select2-material-underline-invalid: red;
  --select2-material-placeholder-color: rgba(0, 0, 0, 0.38);
  --select2-material-selection-background: #ddd;
  --select2-material-option-selected-background: rgba(0, 0, 0, 0.04);
  --select2-material-option-highlighted-text-color: #000;
  --select2-material-option-selected-text-color: #ff5722;
}

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);

  z-index: 100000;
  left: 0;
  top: 0;
}

/* Loader circles */
.loader > span {
  background: #0d3b5d;
  margin: 0 2px;
  animation: bouncingLoader 0.6s infinite alternate;
}

.loader > span:nth-child(4) {
  background-color: #42b4e7;
}

.loader > span:nth-child(1) {
  animation-delay: 0.4s;
}

.loader > span:nth-child(2) {
  animation-delay: 0.3s;
}

.loader > span:nth-child(3) {
  animation-delay: 0.2s;
}

.loader > span:nth-child(4) {
  animation-delay: 0.1s;
}

/* Define the animation called bouncingLoader. */
@keyframes bouncingLoader {
  0% {
    width: 1rem;
    height: 1rem;
    opacity: 1;
    /* transform: translate3d(0); */
    position: relative;
    top: 0;
    animation: none !important;
  }

  30% {
    width: 1rem;
    height: 1rem;
    opacity: 1;
    /* transform: translate3d(0, -30px, 0); */
    position: relative;
    top: 0px;
    animation: none !important;
  }

  80% {
    width: 1rem;
    height: 1rem;
    opacity: 1;
    /* transform: translate3d(0, -30px, 0); */
    position: relative;
    top: -35px;
    animation: none !important;
  }

  100% {
    width: 1rem;
    height: 1rem;
    opacity: 1;
    /* transform: translate3d(0, -30px, 0); */
    position: relative;
    top: -35px;
    animation: none !important;
  }
}
.table-view-all .custom-data-table-div table tr th:first-child {
  padding-left: 20px !important;
}

.custom-table-div .table td:first-child,
.custom-table-div .table th:first-child {
  padding-left: 30px !important;
}

.custom-data-table-div .dataTables_wrapper {
  padding: 20px;
}

.custom-data-table-div .dt-buttons {
  display: none;
}

.custom-data-table-div .dt-buttons .dt-button {
  background-color: #42b4e7;
  border-radius: 3px;
  color: #fff;
  padding: 7px 20px 6px 20px;
  min-height: 36px;
  font-weight: 700;
  border: none;
  transition: all 0.8s;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  margin: 0 10px 15px 0;
}

.custom-data-table-div .dataTables_wrapper .dataTables_length {
  min-height: 36px;
  line-height: 36px;
  text-transform: capitalize;
}

.custom-data-table-div .dataTables_wrapper .dataTables_length select {
  height: 36px;
  padding: 0 10px 0 5px;
  margin: 0 5px;
}

.custom-data-table-div .dataTables_wrapper .dataTables_filter input {
  border: 1px solid #ccc;
  outline: none;
  border-radius: 3px;
  box-shadow: none;
  font-size: 12px;
  margin: 0 0 0 10px;
  min-height: 40px;
}

.custom-data-table-div table.dataTable.no-footer {
  border-bottom: 1px solid #f2f2f2;
}

.custom-data-table-div .dataTables_wrapper .dataTables_info {
  font-size: 14px;
  padding-top: 20px;
}

.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current:hover,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button {
  padding: 5px 10px 4px 10px;
  font-size: 15px;
  border-radius: 4px;
  min-height: 34px;
  top: -3px;
  display: inline-block;
  position: relative;
  margin: 0 4px 0 4px;
  border: 1px solid #42b4e7 !important;
  background-color: #fff !important;
  color: #42b4e7 !important;
}

.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button:hover {
  color: #fff !important;
  border: 1px solid #42b4e7 !important;
  background: #42b4e7 !important;
}

.custom-data-table-div .dataTables_wrapper .dataTables_paginate {
  padding-top: 15px;
  margin-bottom: -5px;
}

.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.current:hover {
  color: #fff !important;
  border: 1px solid #42b4e7 !important;
  background: #42b4e7 !important;
}

.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.first,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.last,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.previous,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.next {
  height: 34px;
  width: 34px;
  border-radius: 100px;
  font-size: 20px;
  padding: 3px;
  display: inline-block;
  background-color: rgba(66, 180, 231, 0.1) !important;
  color: #42b4e7 !important;
  margin: 0 5px 0 5px;
  top: 0px;
  border: none !important;
}

.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.first:hover,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.last:hover,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.previous:hover,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.next:hover {
  background-color: rgba(66, 180, 231) !important;
  color: #fff !important;
}

.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.first.disabled,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.last.disabled,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.previous.disabled,
.custom-data-table-div
  .dataTables_wrapper
  .dataTables_paginate
  .paginate_button.next.disabled {
  background-color: rgba(7, 28, 46, 0.1) !important;
  color: #666 !important;
}

.custom-table-div.custom-data-table-div {
  min-height: auto;
}

@media (max-width: 767px) {
  .custom-data-table-div .dataTables_wrapper .dataTables_filter {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
  }

  .custom-data-table-div .dataTables_wrapper .dataTables_filter label {
    width: 100%;
  }

  .custom-data-table-div .dataTables_wrapper .dataTables_filter input {
    width: calc(100% - 70px);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .custom-table-div.custom-data-table-div {
    margin-top: 15px;
  }
}
.dataTables_filter {
  margin-bottom: 20px;
}

.dataTable .th-headeing {
  color: #071c2e;
}

.dataTable .action .more {
  color: #8e98a0;
  font-size: 24px;
}

.dataTable .action .menu-sort {
  padding: 20px;
  min-width: 200px;
  right: 0 !important;
  left: auto !important;
  -webkit-transform: none;
  transform: none;
}
.dataTable .action .dropdown-menu ul {
  list-style: none;
}
.dataTable .action .dropdown-menu ul li a {
  font-weight: 400;
  padding: 0 20px;
  font-size: 14px;
  color: #071c2e;
  letter-spacing: 0;
  line-height: 40px;
}

.dataTable .action .dropdown-menu ul li span {
  font-size: 19px;
  margin: 5px 10px 0 10px;
  position: relative;
  top: 2px;
}

.dataTable .action .dropdown-menu ul li .delete {
  color: #f45c56;
}

/* Loader circles */

/* Define the animation called bouncingLoader. */
@keyframes bouncingLoader {
  0% {
    width: 1rem;
    height: 1rem;
    opacity: 1;

    /* transform: translate3d(0); */
    position: relative;
    top: 0;
    animation: none !important;
  }

  30% {
    width: 1rem;
    height: 1rem;
    opacity: 1;

    /* transform: translate3d(0, -30px, 0); */
    position: relative;
    top: 0px;
    animation: none !important;
  }

  80% {
    width: 1rem;
    height: 1rem;
    opacity: 1;

    /* transform: translate3d(0, -30px, 0); */
    position: relative;
    top: -35px;
    animation: none !important;
  }

  100% {
    width: 1rem;
    height: 1rem;
    opacity: 1;

    /* transform: translate3d(0, -30px, 0); */
    position: relative;
    top: -35px;
    animation: none !important;
  }
}

.table-view-all .custom-data-table-div table tr th:first-child {
  padding-left: 20px !important;
}

.custom-table-div .table {
  td:first-child,
  th:first-child {
    padding-left: 30px !important;
  }
}

.custom-data-table-div {
  .dataTables_wrapper {
    padding: 20px;
  }

  .dt-buttons {
    display: none;

    .dt-button {
      background-color: #42b4e7;
      border-radius: 3px;
      color: #fff;
      padding: 7px 20px 6px 20px;
      min-height: 36px;
      font-weight: 700;
      border: none;
      transition: all 0.8s;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
      margin: 0 10px 15px 0;
    }
  }

  .dataTables_wrapper {
    .dataTables_length {
      min-height: 36px;
      line-height: 36px;
      text-transform: capitalize;

      select {
        height: 36px;
        padding: 0 10px 0 5px;
        margin: 0 5px;
      }
    }

    .dataTables_filter input {
      border: 1px solid #ccc;
      outline: none;
      border-radius: 3px;
      box-shadow: none;
      font-size: 12px;
      margin: 0 0 0 10px;
      min-height: 40px;
    }
  }

  table.dataTable.no-footer {
    border-bottom: 1px solid #f2f2f2;
  }

  .dataTables_wrapper {
    .dataTables_info {
      font-size: 14px;
      padding-top: 20px;
    }

    .dataTables_paginate {
      .paginate_button {
        &.current {
          padding: 5px 10px 4px 10px;
          font-size: 15px;
          border-radius: 4px;
          min-height: 34px;
          top: -3px;
          display: inline-block;
          position: relative;
          margin: 0 4px 0 4px;
          border: 1px solid #42b4e7 !important;
          background-color: #fff !important;
          color: #42b4e7 !important;

          &:hover {
            padding: 5px 10px 4px 10px;
            font-size: 15px;
            border-radius: 4px;
            min-height: 34px;
            top: -3px;
            display: inline-block;
            position: relative;
            margin: 0 4px 0 4px;
            border: 1px solid #42b4e7 !important;
            background-color: #fff !important;
            color: #42b4e7 !important;
          }
        }

        padding: 5px 10px 4px 10px;
        font-size: 15px;
        border-radius: 4px;
        min-height: 34px;
        top: -3px;
        display: inline-block;
        position: relative;
        margin: 0 4px 0 4px;
        border: 1px solid #42b4e7 !important;
        background-color: #fff !important;
        color: #42b4e7 !important;

        &:hover {
          color: #fff !important;
          border: 1px solid #42b4e7 !important;
          background: #42b4e7 !important;
        }

        &.current {
          color: #fff !important;
          border: 1px solid #42b4e7 !important;
          background: #42b4e7 !important;

          &:hover {
            color: #fff !important;
            border: 1px solid #42b4e7 !important;
            background: #42b4e7 !important;
          }
        }

        &.first,
        &.last,
        &.previous,
        &.next {
          height: 34px;
          width: 34px;
          border-radius: 100px;
          font-size: 20px;
          padding: 3px;
          display: inline-block;
          background-color: rgba(66, 180, 231, 0.1) !important;
          color: #42b4e7 !important;
          margin: 0 5px 0 5px;
          top: 0px;
          border: none !important;
        }

        &.first:hover,
        &.last:hover,
        &.previous:hover,
        &.next:hover {
          background-color: rgba(66, 180, 231) !important;
          color: #fff !important;
        }

        &.first.disabled,
        &.last.disabled,
        &.previous.disabled,
        &.next.disabled {
          background-color: rgba(7, 28, 46, 0.1) !important;
          color: #666 !important;
        }
      }

      padding-top: 15px;
      margin-bottom: -5px;
    }
  }
}

.custom-table-div.custom-data-table-div {
  min-height: auto;
}

@media (max-width: 767px) {
  .custom-data-table-div .dataTables_wrapper .dataTables_filter {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;

    label {
      width: 100%;
    }

    input {
      width: calc(100% - 70px);
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .custom-table-div.custom-data-table-div {
    margin-top: 15px;
  }
}

.dataTables_filter {
  margin-bottom: 20px;
}

.dataTable {
  .th-headeing {
    color: #071c2e;
  }

  .action {
    .more {
      color: #8e98a0;
      font-size: 24px;
    }

    .menu-sort {
      padding: 20px;
      min-width: 200px;
      right: 0 !important;
      left: auto !important;
      -webkit-transform: none;
      transform: none;
    }

    .dropdown-menu ul {
      list-style: none;

      li {
        a {
          font-weight: 400;
          padding: 0 20px;
          font-size: 14px;
          color: #071c2e;
          letter-spacing: 0;
          line-height: 40px;
        }

        span {
          font-size: 19px;
          margin: 5px 10px 0 10px;
          position: relative;
          top: 2px;
        }

        .delete {
          color: #f45c56;
        }
      }
    }
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-table-div .table {
  // th {
  //   background-color: white !important;
  //   border: none !important;
  //   border-bottom: 1px solid #d6d4db !important;
  // }

  tr:nth-child(even) {
    background-color: #f3f3f567;
  }

  td {
    padding: 8px !important;
  }
}

.custom-table-div {
  .btn.btn-edit-action,
  .btn.btn-delete-action {
    background-color: unset !important;
  }
}

// .table-responsive {
//   height: calc(100vh - 140px) !important;
// }

.tabs-root-custom2 {
  height: calc(100vh - 140px) !important;
  margin: unset !important;

  .table-responsive {
    height: calc(100vh - 230px) !important;
  }
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
  width: auto !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1a4480;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #1a4480;
}
.alertButtonSuccess {
  padding: 12px 16px;
  border-radius: 8px;
  min-width: 120px;
  font-weight: 500;
  max-height: 45px;
  white-space: nowrap !important;
  background-color: #0083ff;
  color: white;
  border: none;
}

.alertButtonCancel {
  padding: 12px 16px;
  border-radius: 8px;
  min-width: 120px;
  font-weight: 500;
  max-height: 45px;
  white-space: nowrap !important;
  background-color: #ffffff;
  color: #001c40;
  border: 1px solid #0083ff;
}

.infoButton {
  background-image: "/assets/images/icons/default-app-icons/help-desk.png";
  //padding: 1px 1px;
  //border-radius: 8px;
  min-width: 10px;
  font-weight: 50;
  max-height: 45px;
  white-space: nowrap !important;
  //background-color: #ffffff;
  //color: #001c40;
  //border: 1px solid #0083ff;
}

.alertButtonCancel:hover {
  background-color: #0083ff;
}

.swal2-actions {
  justify-content: end !important;
}

.mat-progress-bar-fill::after {
  background-color: #001c40;
}
